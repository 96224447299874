<template>
  <v-row>
    <v-col md="12">
      <data-table
        :items="reports"
        :headers="headers"
        :custom-columns="['item.project', 'item.member']"
      >
        <template v-slot:custom-render-item.project="{ item }">
          <div class="d-flex align-center">
            <v-icon
              x-small
              class="dot-icon mr-2"
              :class="`success-dot`"
            >
              fiber_manual_record
            </v-icon>
            {{ item.project }}
          </div>
        </template>
        <template v-slot:custom-render-item.member="{ item }">
          <div class="d-flex align-center">
            <div class="custom-member">
              <img
                class="custom-member-img"
                :src="item.member.img"
                alt=""
              >
            </div>
            {{ item.member.name }}
          </div>
        </template>
      </data-table>
    </v-col>
  </v-row>
</template>

<script>
import DataTable from '@/components/collections/DataTable/DataTable';
import { REPORT_DELETED_HEADERS } from '@/views/Report/constants';

export default {
  name: 'Deleted',
  components: { DataTable },
  data() {
    return {
      reports: [
        {
          id: '1',
          member: {
            // eslint-disable-next-line global-require
            img: require('@/static/account.png'),
            name: 'test',
          },
          project: 'Test Project',
          activity: '80',
          duration: '00:55:15',
          timeSpan: '11:00:35 - 12:55:50',
          manual: '100%',
          reasonOfDelete: 'That is not my time. I returned him.',
        },
        {
          id: '2',
          member: {
            // eslint-disable-next-line global-require
            img: require('@/static/account.png'),
            name: 'test',
          },
          project: 'Test Project',
          activity: '0',
          duration: '00:55:15',
          timeSpan: '11:00:35 - 12:55:50',
          manual: '100%',
          reasonOfDelete: 'That is not my time. I returned him.',
        },
        {
          id: '3',
          member: {
            // eslint-disable-next-line global-require
            img: require('@/static/account.png'),
            name: 'test',
          },
          project: 'Test Project',
          activity: '80',
          duration: '00:55:15',
          timeSpan: '11:00:35 - 12:55:50',
          manual: '100%',
          reasonOfDelete: 'That is not my time. I returned him.',
        },
      ],
      headers: REPORT_DELETED_HEADERS,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .custom-member {
    display: flex;
    align-items: center;
    &-img {
      width: 28px;
      height: 28px;
      border: 2px solid $white;
      border-radius: 11px;
      margin-right: 8px;
    }
  }
</style>
